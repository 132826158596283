import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [folders, setFolders] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [password, setPassword] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  const loadFolders = () => {
    axios.get('https://aaaaaaaaa-self.vercel.app/api/list-folders')
      .then(response => {
        setFolders(response.data);
        if (response.data.length > 0) {
          setSelectedFolder(response.data[0].name);
        }
      })
      .catch(error => {
        console.error('Erro ao listar pastas:', error);
      });
  };

  const handleCreateFolderClick = () => {
    setShowCreateFolder(true);
  };

  const closeCreateFolderForm = () => {
    setShowCreateFolder(false);
    setNewFolderName('');
    setPassword('');
  };

  const createFolder = () => {
    if (!newFolderName || !password) {
      showMessage('Por favor, insira o nome da pasta e a senha.', 'error');
      return;
    }

    axios.post('https://aaaaaaaaa-self.vercel.app/api/create-folder', { newFolderName, password })
      .then(() => {
        showMessage('Pasta criada com sucesso!', 'success');
        loadFolders();
        closeCreateFolderForm();
      })
      .catch(error => {
        const errorMsg = error.response?.data || 'Erro ao criar a pasta.';
        showMessage(errorMsg, 'error');
      });
  };

  const uploadImages = () => {
    if (!selectedFiles.length || !selectedFolder) {
      showMessage('Selecione pelo menos um arquivo e uma pasta.', 'error');
      return;
    }
  
    setLoading(true);
    setProgress(0);
  
    const totalFiles = selectedFiles.length;
    let completed = 0;
  
    const uploadPromises = selectedFiles.map((file) => {
      const formData = new FormData();
      formData.append('file', file);
  
      return axios.post(`https://aaaaaaaaa-self.vercel.app/api/upload?folder=${selectedFolder}`, file, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          completed += 1;
          setProgress((completed / totalFiles) * 100);
        });
    });
  
    Promise.all(uploadPromises)
      .then(() => {
        showMessage('Imagens enviadas com sucesso!', 'success');
        setSelectedFiles([]);
        setLoading(false);
      })
      .catch(() => {
        showMessage('Erro ao enviar imagens.', 'error');
        setLoading(false);
      });
  };
  

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validExtensions = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

    const filteredFiles = files.filter(file => validExtensions.includes(file.type));

    if (filteredFiles.length + selectedFiles.length > 25) {
      showMessage('Você pode selecionar no máximo 25 arquivos.', 'error');
      return;
    }

    setSelectedFiles(prevFiles => [...prevFiles, ...filteredFiles]);
  };

  const showMessage = (message, type) => {
    if (type === 'success') {
      setSuccessMessage(message);
    } else {
      setErrorMessage(message);
    }

    setTimeout(() => {
      setSuccessMessage('');
      setErrorMessage('');
    }, 3000);
  };

  useEffect(() => {
    loadFolders();
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-gray-100 p-4 relative">
      {(successMessage || errorMessage) && (
        <div className={`absolute top-4 left-1/2 transform -translate-x-1/2 ${successMessage ? 'bg-green-600' : 'bg-red-600'} text-white py-2 px-4 rounded shadow-lg z-50`}>
          {successMessage || errorMessage}
          <div
            className="absolute bottom-0 left-0 h-1 bg-white"
            style={{
              animation: 'loadBar 3s linear forwards',
            }}
          ></div>
        </div>
      )}

      <div className="w-full max-w-md bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center mb-6">Compartilhe Suas Fotos</h2>

        {!showCreateFolder && (
          <div className="mb-4">
            <button
              onClick={handleCreateFolderClick}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded mb-2"
              disabled={loading}
            >
              Criar Nova Pasta
            </button>
          </div>
        )}

        {showCreateFolder && (
          <div className="mb-4 relative">
            <h3 className="text-lg font-medium mb-4">Criação de Nova Pasta</h3>
            <button
              onClick={closeCreateFolderForm}
              className="absolute -top-10 left-4 bg-red-600 hover:bg-red-700 text-white rounded-full w-8 h-8 flex items-center justify-center"
            >
              &times;
            </button>
            <label className="block text-gray-400 mb-2">Nome da Pasta</label>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Digite o nome da pasta"
              className="w-full mb-4 p-2 rounded border border-gray-600 bg-gray-700"
              disabled={loading}
            />
            <label className="block text-gray-400 mb-2">Senha</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Digite a senha"
              className="w-full mb-4 p-2 rounded border border-gray-600 bg-gray-700"
              disabled={loading}
            />
            <button
              onClick={createFolder}
              className="w-full bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded"
              disabled={loading}
            >
              Confirmar Criação
            </button>
          </div>
        )}

        {!showCreateFolder && (
          <>
            <hr className="border-gray-600 my-4" />
            <div>
              <h3 className="text-lg font-medium mb-2">Enviar Arquivos</h3>
              <select
                onChange={(e) => setSelectedFolder(e.target.value)}
                value={selectedFolder}
                className="w-full mb-2 p-2 rounded border border-gray-600 bg-gray-700"
                disabled={loading}
              >
                {folders.map((folder) => (
                  <option key={folder.name} value={folder.name}>{folder.name}</option>
                ))}
              </select>

              <div className="mb-4">
                <input
                  type="file"
                  onChange={handleFileChange}
                  accept="image/*"
                  multiple
                  className="w-full mb-2 p-2 rounded border border-gray-600 bg-gray-700"
                  disabled={loading}
                />
                <small className="text-gray-400">
                  {selectedFiles.length > 0
                    ? `${selectedFiles.length} arquivo(s) selecionado(s)`
                    : 'Nenhum arquivo selecionado'}
                </small>
              </div>

              <button
                onClick={uploadImages}
                className="w-full bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded"
                disabled={loading}
              >
                {loading ? 'Enviando...' : 'Enviar Arquivos'}
              </button>

              {loading && (
                <div className="w-full bg-gray-700 rounded mt-4">
                  <div
                    className="bg-green-500 h-2 rounded"
                    style={{ width: `${progress}%` }}
                  />
                </div>
              )}

              <div className="grid grid-cols-3 gap-2 mt-4">
                {selectedFiles.map((file, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={`preview-${index}`}
                    className="w-full h-24 object-cover rounded"
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>

      <style jsx global>{`
        @keyframes loadBar {
          from {
            width: 0%;
          }
          to {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
}

export default App;